<template>
    <span v-if="!loading" >
            <v-row class="mx-2 mt-1">
                    <v-card class="mb-2" elevation="4"  width="100%" height="30">
                         <span class="d-flex justify-start ml-3 font-size12 font-weight-bold mt-2">CPT Code 99453</span>
                        <v-card class="px-4 elevation-0 font-size12" v-if="details?.cptCode99453">
                            <v-row dense class="d-flex justify-space-between ">
                                <span>{{ $t("device") }}</span>
                                <span class="font-size12 font-weight-bold">{{ details.cptCode99453.deviceTypeTitle }}</span>
                            </v-row>
                            <v-row dense class="d-flex justify-space-between ">
                                <span>{{ $t("generated") }}</span>
                                <span class="font-size12 font-weight-bold">{{ details.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone)}}</span>
                            </v-row>
                        </v-card>
                    </v-card>
            </v-row>
      <v-row dense class="px-2">
        <v-col cols="3">
        <v-card width="100%" elevation="4" class="pa-1" height="70">
             <span class="d-flex justify-start ml-3 font-size12 font-weight-bold mt-2">CPT Code 99454</span>
                            <v-card class="px-4 elevation-0 font-size12" v-if="item?.cptCode99454">
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="mt-1" > {{ $t("measurements") }}</span>
                                    <span class="font-size12 font-weight-bold">{{ item.cptCode99454.totalMeasurements }}</span>
                                </v-row>
                                <v-row dense class="d-flex justify-space-between">
                                    <span class="mt-1"> {{ $t("generated") }}</span>
                                    <span class="font-size12 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone) }}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>

        <v-col cols="3">
        <v-card width="100%" elevation="4" class="pa-1" height="70">
                             <span class="d-flex justify-start ml-3 font-size12 font-weight-bold mt-2">CPT Code 99457</span>
                            <v-card class="px-4 elevation-0 font-size12" v-if="item">
                                <v-row dense class="d-flex justify-space-between " >
                                    <span class="font-size12 mt-2">{{ $t("time") }}</span>
                                    <span class="font-size12 font-weight-bold" v-if="item?.cptCode99457">{{secToMinV2(item?.cptCode99457.totalIntervalInSeconds)}}</span>
                                </v-row>
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size12">{{ $t("generated") }}</span>
                                    <span class="font-size12 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone) }}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>

        <v-col cols="3">
        <v-card  width="100%" elevation="4" class="pa-1" height="70">
                                <span class="d-flex justify-start ml-3 font-size12 font-weight-bold mt-2">CPT Code 99458 1st</span>
                            <v-card class="px-4 elevation-0 font-size12" v-if="item?.cptCode99458First">
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size12 mt-2">{{ $t("time") }}</span>
                                    <span class="font-size12 font-weight-bold">{{secToMinV2( item.cptCode99458First.totalIntervalInSeconds)}}</span>
                                </v-row>
                                <v-row dense  class="d-flex justify-space-between ">
                                    <span class="font-size12">{{ $t("generated") }}</span>
                                    <span class="font-size12 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone) }}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>

        <v-col cols="3">
         <v-card  width="100%" elevation="4" class="pa-1" height="70">
                               <span class="d-flex justify-start ml-3 font-size12 font-weight-bold mt-2">CPT Code 99458 2nd</span>
                            <v-card class="px-4 elevation-0 font-size12" v-if="item?.cptCode99458Second">
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size12 mt-2">{{ $t("time") }}</span>
                                    <span class="font-size12 font-weight-bold">{{secToMinV2( item.cptCode99458Second.totalIntervalInSeconds)}}</span>
                                </v-row>
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size12">{{ $t("generated") }}</span>
                                    <span class="font-size12 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone)}}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>
        </v-row>

        <v-row class="mx-1 mt-n1" dense>
            <v-col cols="6">
                <v-text-field
                    class="my-1  mb-2"
                    v-model="search99454"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense
                    outlined
                    hide-details
                ></v-text-field>
                <v-data-table
                    :loading="newLoading"
                    :headers="cptCode99454Headers"
                    :no-data-text="$t('no-data-available')"
                    :loading-text="$t('loading')"
                    :headers-length="cptCode99454Headers.length"
                    :search="search99454"
                    :items="cptCode99454WithDetails"
                    :items-per-page="-1"
                    sort-by="id"
                    item-key="id"
                    fixed-header
                    dense
                    class="elevation-4"
                    style=" overflow-y: auto;" width="100%" height="300"
                    :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                >
                    <template v-slot:[`item.measurementDeviceTypeTitle`]="{ item }">
                      <td class="text-left font-size12">
                        {{ item.measurementDeviceTypeTitle }}
                      </td>
                    </template>
                    <template v-slot:[`item.measuredIntervalFrom`]="{ item }">
                        <td class="text-left font-size12">
                        {{ item.measuredIntervalFrom | dateformat("datetime_short_with_seconds", timeLocalization) }}
                      </td>

                    </template>
                </v-data-table>
              </v-col>
                  <v-col cols="6">
                    <v-text-field
                        class="my-1 mb-2"
                        v-model="search99457"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                    <v-data-table
                        :loading="newLoading"
                        :no-data-text="$t('no-data-available')"
                        :loading-text="$t('loading')"
                        :headers="cptCode99457Headers"
                        :headers-length="cptCode99457Headers.length"
                        :search="search99457"
                        :items="cptCode99457WithDetails"
                        :items-per-page="-1"
                        :item-key="'refAppointmentId'"
                        dense
                        fixed-header
                        class="elevation-4"
                        style="overflow-y: auto;" width="100%" height="300"
                        :footer-props="{
                        itemsPerPageText: this.$t('row'),
                        itemsPerPageAllText: this.$t('all'),
                      }"
                    >

                        <template v-slot:[`item.billingItemType`]="{ item }">
                          <td class="font-size12 text-left">
                            {{item.billingItemType}}
                          </td>
                        </template>
                        <template v-slot:[`item.eventStartedAt`]="{ item }">
                          <td class="font-size12 text-left">
                             {{ item.eventStartedAt | dateformat("datetime_short_with_seconds", timeLocalization) }}
                          </td>
                        </template>
                        <template v-slot:[`item.eventEndedAt`]="{ item }">
                           <td class="font-size12 text-left">
                            <span v-if="item.billingItemType !== 'MEDICAL_NOTE'">
                              {{ item.eventEndedAt | dateformat("datetime_short_with_seconds", timeLocalization) }}
                            </span>
                          </td>
                        </template>
                      <template v-slot:[`item.duration`]="{ item }">
                         <td class="font-size12 text-left">
                         {{ calculateDuration(item.eventStartedAt, item.eventEndedAt, item) }}
                        </td>
                    </template>
                    </v-data-table>
            </v-col>
        </v-row>
    </span>
</template>
<script>
import { mapState } from 'vuex';

export default {
	props: ['item'],
	data () {
		return {
			itemsType: [],
			loading: false,
			search99457: null,
			search99454: null,
			timeLocalization: this.$i18n.locale,
      loadingTimeTracking: false,
      newLoading: true,
		};
	},
	computed: {
		...mapState({
			bilingItemType: state => state.billings.billingDetailsForPatientForMonth,
			details: state => state.billings.billingDetailsForPatientForMonth?.billingHeaderDetailsData,
			userDetails: (state) => state.authentication.userData,
		}),
		cptCode99454WithDetails () {
			return this.details?.cptCode99454WithDetails?.details;
		},
		cptCode99457WithDetails () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loadingTimeTracking = true;
			const data = [];
			var dataItem = {};
			if (this.details?.cptCode99457WithDetails) {
				this.details.cptCode99457WithDetails.details9945799458.forEach(element => {
					var itemType = this.itemsType[element.billingItemType - 1];
					dataItem = {
						billingItemType: itemType,
						measurementDeviceTypeTitle: element.measurementDeviceTypeTitle,
						measuredIntervalFrom: element.measuredIntervalFrom,
						eventStartedAt: element.eventStartedAt,
						eventEndedAt: element.eventEndedAt,
            intervalInSeconds: element.intervalInSeconds,
					};
					data.push(dataItem);
				});
			}
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loadingTimeTracking = false;
			return data;
		},
		cptCode99454Headers () {
			return [
				// {
				// 	text: 'Id',
				// 	value: 'billingReferenceId',
				// },
				// {
				// 	text: 'Item type',
				// 	value: 'billingItemType',
				// },
				{
					text: this.$t('device'),
					value: 'measurementDeviceTypeTitle',
          class: 'secondary',
          align: 'start',
				},
				{
					text: this.$t('time'),
					value: 'measuredIntervalFrom',
          class: 'secondary',
          align: 'start',
				},
			];
		},
		cptCode99457Headers () {
			return [
				{
					text: this.$t('interaction-type'),
					value: 'billingItemType',
          class: 'secondary',
          align: 'start',
				},
				{
					text: this.$t('started'),
					value: 'eventStartedAt',
          class: 'secondary',
          align: 'start',
				},
				{
					text: this.$t('ended'),
					value: 'eventEndedAt',
          class: 'secondary',
          align: 'start',
				},
        {
          text: this.$t('duration'),
          value: 'duration',
          class: 'secondary',
          align: 'start',
        },
			];
		},
	},
	watch: {
		item: {
			immediate: true,
			deep: true,
			async handler (value) {
				// await this.initialize({ patientId: value.patientId, month: value.billingMonth, year: new Date().getUTCFullYear() });
                await this.initialize({ patientId: value.patientId, month: value.billingMonth, year: value.billingYear });
				this.loading = false;
				this.getType();
			},
		},
	},
	// created () {
	// 	this.getType();
	// },
	methods: {
    calculateDuration (startTime, endTime, item) {
      if (item.billingItemType === 'MEDICAL_NOTE') {
        // Convert duration to minutes and seconds
        const minutes = Math.floor(item.intervalInSeconds / 60);
        const seconds = item.intervalInSeconds % 60;

        // Format the duration string
        return `${minutes}m ${seconds}s`;
      }

      const start = new Date(startTime);
      const end = new Date(endTime);
      const durationInMilliseconds = end - start;

      // Convert duration to hours, minutes, and seconds
      const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000);
      const seconds = Math.floor((durationInMilliseconds % 60000) / 1000);

      // Format the duration string
      return `${minutes}m ${seconds}s`;
    },
        secToMinV2 (seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;

            return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
        },
		async initialize (headers) {
      this.newLoading = true;
			await this.$store.dispatch('billings/getbillingdetailsforpatientformonth', headers).then(res => {});
      this.newLoading = false;
		},
		getType () {
			if (this.bilingItemType?.billingItemTypes) {
				const count = Object.keys(this.bilingItemType?.billingItemTypes).length;
				for (let i = 1; i <= count; i++) {
					this.itemsType.push(this.bilingItemType?.billingItemTypes?.[i]);
				}
			}
		},
	},
};
</script>
